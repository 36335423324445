/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_PTyqMeb6H",
    "aws_user_pools_web_client_id": "67jsl6nekeq084suo8drt1d7gs",
    "oauth": {}
};


export default awsmobile;
