import React from 'react';

const BodyTag = props => {
    return(
        <noscript>
            <iframe 
                src="https://www.googletagmanager.com/ns.html?id=GTM-56DZZMJ"
                style={{display: 'none', visibility: 'hidden', height: 0, width: 0}}
            >
            </iframe>
        </noscript>
    );
}

export default BodyTag;