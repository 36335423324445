// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = 'https://11ddbe2a85c6469db1810959becd8ae7@o932282.ingest.sentry.io/5882708';

Sentry.init({
  dsn: SENTRY_DSN || 'https://11ddbe2a85c6469db1810959becd8ae7@o932282.ingest.sentry.io/5882708',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  debug: true,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
