/******************************************
 *  Author : Suraj Sharma
 *  Created On : Mon Feb 08 2021
 *  File : _app.jsx
 *******************************************/

import React from 'react';
import Amplify from 'aws-amplify';
import {createWrapper} from 'next-redux-wrapper';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import {useRouter} from 'next/router';

import reduxStore from '../store';
import authStatuses from '../config/constants/AuthConstants';
import awsconfig from '../config/constants/aws-exports';

import { getImpossibleVideoInfo } from '../store/impossibleVideo/ThunkActions';
import { getCompanyForestInfo } from '../store/companyForest/ThunkActions';

import Header from '../components/header';
import FooterNavigator from '../components/footerNavigator';
import { CustomHeader } from '../components/commons';

import AuthStateHandler from '../utils/AuthStateHandler';
import BodyTag from '../../public/vendor/gtm/BodyTag';
import { postAuthPaths, preAuthPaths, allPaths } from '../config/constants/NavigationConstants';

import '../../public/vendor/bootstrap/css/bootstrap.min.css';
import '../../public/css/font-awesome.css';
import '../../public/css/fontawesome-all.css';
import '../../public/css/style.css';
import '../../public/fonts/stylesheet.css';
import '../../public/css/bio.css';
import '../../public/css/datetime.css';

Amplify.configure(awsconfig);

const App = ({Component, pageProps}) => {

    const dispatch = useDispatch();
    
    const { authStatus } = useSelector((state) => state.authReducer);
    const isAuthenticated = authStatus !== authStatuses.LOGGED_OUT;
    
    const { 
        choosenTrees, 
        urlApiSource, 
    } = useSelector((reduxState) => reduxState.treesReducer);
        
    const router = useRouter();
    const {pathname} = router;

    const pathApproved = AuthStateHandler(isAuthenticated, pathname);

    // console.log(pathname, pathApproved);

    const routeQUery = router.query;
    const isDynamicPage = Object.keys(routeQUery).includes('dynamicPage');
    React.useEffect(() => {
      if (
        Object.keys(routeQUery).includes("dynamicPage") &&
        !["sw.js", "vendor"].includes(routeQUery.dynamicPage)
      ) {
        if (routeQUery.pageName) {
          dispatch(
            getImpossibleVideoInfo({
              pathName: routeQUery.dynamicPage,
              restaurantName: routeQUery.pageName,
            })
          );
        } else {
          dispatch(
            getCompanyForestInfo({
              pathName: routeQUery.dynamicPage,
            })
          );
        }
      }
    }, [routeQUery.dynamicPage]);


    if(!pathApproved && typeof window !== 'undefined') {

        

        // if path is not approved
        if(isAuthenticated){
            // for a user who has already loggedIn 
            // we have two places he can be redirected to
            // either portal (Home) or forest page
            // check if the user has already choosen any trees
            console.log("path exists", pathname, allPaths.includes(pathname));
            if(Object.keys(choosenTrees).length > 0 || urlApiSource==='restaurant' || urlApiSource==='sustainable_meeting' || !allPaths.includes(pathname))
            {
                if(!isDynamicPage)
                    router.push(postAuthPaths.FOREST);
            }else {
                router.push(postAuthPaths.HOME);
            }
        }else {
            if(!isDynamicPage)
                router.push(preAuthPaths.FOREST);
        }
    }

    const renderSection = () => {
        if(!pathApproved) {
            if(!isDynamicPage)
                return null;
        }
        let addClassForProfilePage = pathname.indexOf('profile') > -1 ? 'banner-sec profile-wrap' : pathname.indexOf('vlog') > -1 ? 'banner-sec vlog-main-wrap' : 'banner-sec';
        
        if(pathname === preAuthPaths.MAPUPDATE){
            return(
                <>
                    <CustomHeader />
                    <BodyTag />                    
                    <Component {...pageProps} />
                </>
            )
        }
        if (!isAuthenticated) {
            // for users who are not logged In
            // but profile page is created based on
            // loggedIn css codes so we will conditionally render this section
            if(pathname.includes(preAuthPaths.PROFILE)) {
                return(
                    <>
                        <BodyTag />                    
                        <Header 
                            isLoggedIn={false}
                        />                    
                        <div className="banner-sec">
                            <Component {...pageProps} />
                        </div>
                    </>
                )
            }
            return (
                <>
                    <CustomHeader />
                    <BodyTag />                    
                    <Header isLoggedIn={ false } />
                    <Component {...pageProps} />
                </>
            )
        }

        // Home page after user has been authenticated
        if(pathname === "/"){
            return(
                <>
                    <CustomHeader />
                    <BodyTag />                    
                    <div style={{width: '100vw', position:'relative'}} className="headerAfterLogin">
                        <Header isLoggedIn style={ {backgroundColor: 'white', zIndex: 999} }/>
                    </div>
                    <Component {...pageProps} />
                </>
            )
        }
        // for pages other than HOME
        return(
            <>
                <CustomHeader />            
                <div className={addClassForProfilePage}>
                    <BodyTag />       
                    <Header isLoggedIn style={ {backgroundColor: '#ffffff', zIndex: 999} }/>
                    <Component {...pageProps} />
                    {/* {pathname.includes('/vlog') === false  ?
                    !pathname.includes('/profile/') && <FooterNavigator pathname={pathname}/>
                    : null } */}

                    {(!['/profile/', '/[dynamicPage]'].includes(pathname)) && <FooterNavigator pathname={pathname}/>}
                
                </div>
            </>
        )
    }

    return(
        <Provider store={reduxStore}>
            {
                /**
                 * Redux persist doesnt allow SSR
                 * We need to check the status of client
                 */
                process.browser
                ?
                <PersistGate persistor={reduxStore.__PERSISTOR} loading={null}>
                    {renderSection()}
                </PersistGate>
                :
                renderSection()
            }
        </Provider>
    );
}

const makestore = () => reduxStore;
const wrapper = createWrapper(makestore);

export default wrapper.withRedux(App);