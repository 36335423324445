/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux'
import { postAuthPaths, preAuthPaths } from '../../config/constants/NavigationConstants';
import { CustomButton } from '../commons';
import { resetAuthState } from '../../store/authentication/ThunkActions';
// eslint-disable-next-line no-unused-vars
import { resetTreeState } from '../../store/trees/ThunkActions';
import styles from './Header.module.css'

const Header = ({ isLoggedIn, style }) => {
    const router = useRouter();
    const { pathname } = router;

    const dispatch = useDispatch();
    // eslint-disable-next-line no-unused-vars
    const [state, setState] = React.useState({
        showSideMenu: false
    });

    const toggleSideMenu = () => {
        setState({
            showSideMenu: !state.showSideMenu
        })
    }

    // to be used for logging out user
    // if he is loggedIn
    const doLogout = async () => {
        toggleSideMenu();
        // all the selected trees should be cleaned up
        // all the data fetched after this user had last logged in should be cleaned up
        dispatch(resetTreeState());
        dispatch(resetAuthState());
    }

    const { allowedTree } = useSelector((currentState) => currentState.treesReducer);
    const { credentials: { profileImage } } = useSelector((authState) => authState.authReducer);
    // console.log(profileImage);

    if (!isLoggedIn) {
        return (
            <header>
                <div className="header-wrap">
                    <div className="container-wrap">
                        <div className="header-logo">
                            <figure className="mb-0 img-logo">
                                <Link href={preAuthPaths.HOME}><img src="/images/logo.png" alt="" className="img-fluid" /></Link>
                            </figure>
                            <ul className="header-nav">
                                {/* <li>
                                    <Link href={ preAuthPaths.HOME }>HOME</Link>
                                </li>*/}
                                {/* <li>
                                    <Link href={ preAuthPaths.FOREST }>CHOOSE YOUR TREE</Link>
                                </li> */}
                            </ul>
                        </div>
                        <div className="header-menu">
                            <ul>
                                <li>
                                    <Link href={preAuthPaths.LOGIN}>Log In</Link>
                                </li>
                                <li>
                                    <Link href={preAuthPaths.SIGNUP}>Sign Up</Link>
                                </li>
                                <li>
                                    <Link href={preAuthPaths.FOREST}>Choose Your GiftTree</Link>
                                </li>
                            </ul>
                            <div className="header-item-right">
                            <a className="store-btn mr-0" target="_blank" href={"https://gifttrees.store/collections/gifttrees?utm_source=referral&utm_medium=cta&utm_campaign=gifttrees_main_site_referral&utm_id=gifttrees_main_site&utm_term=header_cta_buy_gifttrees&utm_content=header_cta_buy_gifttrees"}>
                            <img alt="Not Found" src="/images/buy-gifttree-btn.png" className="img-fluid" />
                        </a>
                        </div>
                            <div
                                className="menu-bar"
                                role="button"
                                tabIndex={0}
                                onClick={toggleSideMenu}
                                onKeyDown={() => { }}
                            >
                                <img alt="Not Found" src="/images/hamburger-menu.png" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`responsive-wrap ${state.showSideMenu ? 'd-block' : 'd-none'}`}>
                    <div className="logo-wrap">
                        <figure className="mb-0">
                            <img src="/images/white-logo.png" className="img-fluid" alt="" />
                        </figure>
                        <div className="header-item-right">
                       
                        <button type="button" className="close-btn" onClick={toggleSideMenu}>
                            CLOSE X
                        </button>
                        </div>
                    </div>
                    <ul>
                        <li>
                        <a className="store-btn" target="_blank" href={"https://gifttrees.store/collections/gifttrees?utm_source=referral&utm_medium=cta&utm_campaign=gifttrees_main_site_referral&utm_id=gifttrees_main_site&utm_term=mobile_menu_cta_buy_gifttrees&utm_content=mobile_menu_cta_buy_gifttrees"}>
                            <img alt="Not Found" src="/images/buy-gifttree-btn.png" className="img-fluid" />
                        </a>
                        </li>
                        <li>
                            <Link href={preAuthPaths.HOME}>
                                <a onClick={toggleSideMenu}>
                                    HOME
                                </a>
                            </Link>
                        </li>
                        <li>
                            <Link href={preAuthPaths.FOREST}>
                                <a onClick={toggleSideMenu}>
                                    CHOOSE YOUR TREE
                                </a>
                            </Link>
                        </li>
                        <li>
                            <Link href={preAuthPaths.LOGIN}>
                                <a onClick={toggleSideMenu}>
                                    LOG IN
                                </a>
                            </Link>
                        </li>
                        <li>
                            <Link href={preAuthPaths.SIGNUP} onClick={toggleSideMenu}>
                                <a onClick={toggleSideMenu}>
                                    SIGN UP
                                </a>
                            </Link>
                        </li>
                    </ul>
                </div>
            </header>
        )
    }

    /**
     * if logged in then the header returned is different
     * as below
     */

    return (
        <>
            
            <div className="banner-header" style={style}>
                
            { pathname !== "/" && 
            <> 
            <div className="container-wrap">
                <figure className={styles.headerLogo}>
                    <Link href={postAuthPaths.HOME}>
                        <img alt="Not Found" src="/images/logo_black.png" className="img-fluid pointer" />
                    </Link>
                </figure>
                <div className="header-item-right">
                <a className="store-btn" target="_blank" href={"https://gifttrees.store/collections/gifttrees?utm_source=referral&utm_medium=cta&utm_campaign=gifttrees_main_site_referral&utm_id=gifttrees_main_site&utm_term=header_cta_buy_gifttrees&utm_content=header_cta_buy_gifttrees"}>
                            <img alt="Not Found" src="/images/buy-gifttree-btn.png" className="img-fluid" />
                        </a>
                <div
                    className={styles.headerMenu}
                    role="button"
                    tabIndex={0}
                    onClick={toggleSideMenu}
                    onKeyDown={() => { }}
                >
                    <img alt="Not Found" src="/images/hamburger-menu.png" />
                </div>
                </div>
                </div>
            </>
            }
                {pathname === "/" && 
                <div className="container-wrap">
                    <figure className="mb-0 img-logo">
                        <Link href={postAuthPaths.HOME}>
                            <img alt="Not Found" src="images/logo_black.png" className="img-fluid" />
                        </Link>
                    </figure>
                    <div className="header-item-right">
                    <a className="store-btn" target="_blank" href={"https://gifttrees.store/collections/gifttrees?utm_source=referral&utm_medium=cta&utm_campaign=gifttrees_main_site_referral&utm_id=gifttrees_main_site&utm_term=header_cta_buy_gifttrees&utm_content=header_cta_buy_gifttrees"}>
                            <img alt="Not Found" src="/images/buy-gifttree-btn.png" className="img-fluid" />
                        </a>
                    <div
                        className="menu-bar"
                        role="button"
                        tabIndex={0}
                        onClick={toggleSideMenu}
                        onKeyDown={() => { }}
                    >
                        <img alt="Not Found" src="/images/hamburger-menu.png" />
                    </div>
                    </div>
                </div>
                }
                <div className="responsive-wrap" style={{ display: state.showSideMenu ? 'block' : 'none' }}>
                    <div className="logo-wrap">
                        <figure className="mb-0 img-logo">
                            <Link href={postAuthPaths.HOME}>
                                <img alt="Not Found" src="/images/white-logo.png" className="img-fluid" onClick={toggleSideMenu} />
                            </Link>
                        </figure>
                        <div className="header-item-right">
                        
                        <button type="button" className="close-btn" onClick={toggleSideMenu}>
                            CLOSE X
                        </button>
                        </div>
                    </div>
                    <ul>
                        <li>
                            <div className="pro-img">
                                <figure className="mb-0 menu-profile-image">
                                    <img alt="Not Found" src={profileImage} className="img-fluid" />
                                </figure>
                            </div>
                        </li>
                        <li>
                        <a className="store-btn" target="_blank" href={"https://gifttrees.store/collections/gifttrees?utm_source=referral&utm_medium=cta&utm_campaign=gifttrees_main_site_referral&utm_id=gifttrees_main_site&utm_term=mobile_menu_cta_buy_gifttrees&utm_content=mobile_menu_cta_buy_gifttrees"}>
                            <img alt="Not Found" src="/images/buy-gifttree-btn.png" className="img-fluid" />
                        </a>
                        </li>
                        <li>
                            <Link href={postAuthPaths.HOME_ALIAS}>
                                <a onClick={toggleSideMenu}>
                                    My Account
                                </a>
                            </Link>
                        </li>
                        <li>
                            <Link href={postAuthPaths.ABOUT}>
                                <a onClick={toggleSideMenu}>
                                    About GiftTrees
                            </a>
                            </Link>
                        </li>
                        <li>
                            <Link href={`${postAuthPaths.FOREST}?switcherState=RIGHT`}>
                                <a onClick={toggleSideMenu}>
                                    Choose More Trees
                            </a>
                            </Link>
                        </li>
                        <li onClick={doLogout} onKeyDown={() => { }}>
                            <Link href={postAuthPaths.HOME}>
                                Log Out
                        </Link>
                        </li>
                        <li>
                            <div className="circle yellow-cl">
                                <h6>{allowedTree}<span>{allowedTree > 1 ? 'TREES' : 'TREE'} TO PLANT</span></h6>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

Header.propTypes = {
    isLoggedIn: PropTypes.bool,
    style: PropTypes.instanceOf(Object)
};

Header.defaultProps = {
    isLoggedIn: false,
    style: {}
};

export default Header
